<template>
  <div>
    <b-card-actions
      title="Task"
      action-refresh
      @refresh="onRefresh"
      ref="mainCard"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <!-- <b-dropdown
            id="dropdown-grouped"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            right
            class="dropdown-icon-wrapper"
            size="sm"
          >
            <template #button-content>
              <div class="d-flex align-items-center px-3">
                <feather-icon icon="PlusIcon" size="18" />
                <span class="mr-1" style="font-size: 14px">New</span>
              </div>
            </template>
            <b-dropdown-item v-b-modal.add_task>
              <div class="d-flex align-items-center">
                <feather-icon icon="FlagIcon" size="18" class="mr-1" />
                <span class="font-weight-bold">Add To-Do</span>
              </div>
            </b-dropdown-item> -->
          <!-- <b-dropdown-item>
              <b-link to="project/milestone" class="text-secondary">
                <div class="d-flex align-items-center">
                  <feather-icon icon="MapIcon" size="18" class="mr-1" />
                  <span class="font-weight-bold">Add Milestone</span>
                </div>
              </b-link>
            </b-dropdown-item> -->
          <!-- </b-dropdown> -->
          <b-button v-b-modal.add_task variant="primary" size="sm">
            <feather-icon icon="PlusIcon" size="18" /> New Task
          </b-button>
          <b-link to="/project/todo-list">
            <b-button variant="secondary" size="sm" class="ml-1">
              <feather-icon icon="MenuIcon" size="18" /> Table View
            </b-button>
          </b-link>
        </div>

        <b-button variant="secondary" size="sm">
          <feather-icon icon="FilterIcon" />
        </b-button>
      </div>

      <div class="d-flex mt-2 pb-3" style="max-width: 100%; overflow-x: auto">
        <b-col md="3">
          <div
            class="d-flex justify-content-between align-items-center px-2 head"
          >
            <span class="bold">
              <b-badge variant="primary"> {{ newList.length }} </b-badge> New
            </span>
            <b-dropdown
              id="dropdown-grouped"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="transparant"
              right
              class="dropdown-icon-wrapper"
              size="sm"
            >
              <template #button-content>
                <div class="d-flex align-items-center">
                  <feather-icon icon="MenuIcon" size="18" />
                </div>
              </template>
              <b-dropdown-item>
                <div class="d-flex align-items-center">
                  <feather-icon icon="EditIcon" size="13" />
                  <span>Edit Label</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <draggable
            :list="newList"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
            style="min-height: 300px"
            @change="onDragNew"
          >
            <b-list-group-item
              v-for="(listItem, index) in newList"
              :key="index"
              tag="li"
            >
              <b-card style="border-left: solid 2px blue">
                <div class="d-flex">
                  <div class="ml-25">
                    <a
                      class="mb-0 font-weight-bold text-primary"
                      v-b-modal="'detail_todo_' + listItem.id"
                    >
                      {{ listItem.name }}
                    </a>
                    <div v-html="truncateText(listItem.description, 50)"></div>
                  </div>
                </div>
                <div class="d-flex justify-content-end align-items-center mt-1">
                  <select
                    class="form-control-select"
                    @change="update_priority($event, listItem)"
                  >
                    <option
                      value="high"
                      :selected="listItem.priority == 'high'"
                    >
                      High
                    </option>
                    <option
                      value="medium"
                      :selected="listItem.priority == 'medium'"
                    >
                      Medium
                    </option>
                    <option value="low" :selected="listItem.priority == 'low'">
                      Low
                    </option>
                  </select>
                </div>
              </b-card>
            </b-list-group-item>
          </draggable>
        </b-col>

        <!-- <b-col md="3">
          <div
            class="d-flex justify-content-between align-items-center px-2 head"
          >
            <span class="bold">
              <b-badge variant="danger"> {{ blockedList.length }} </b-badge>
              Blocked
            </span>
            <b-dropdown
              id="dropdown-grouped"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="transparant"
              right
              class="dropdown-icon-wrapper"
              size="sm"
            >
              <template #button-content>
                <div class="d-flex align-items-center">
                  <feather-icon icon="MenuIcon" size="18" />
                </div>
              </template>
              <b-dropdown-item>
                <div class="d-flex align-items-center">
                  <feather-icon icon="EditIcon" size="13" />
                  <span>Edit Label</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          
          <draggable
            :list="blockedList"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
            style="min-height: 300px"
            @change="onDragBlocked"
          >
            <b-list-group-item
              v-for="(listItem, index) in blockedList"
              :key="index"
              tag="li"
            >
              <b-card style="border-left: solid 2px red">
                <div class="d-flex">
                  <div class="ml-25">
                    <a
                      class="mb-0 font-weight-bold text-primary"
                      v-b-modal="'detail_todo_' + listItem.id"
                    >
                      {{ listItem.name }}
                    </a>
                    <div v-html="truncateText(listItem.description, 50)"></div>
                  </div>
                </div>
                <div class="d-flex justify-content-end align-items-center mt-1">
                  <select
                    class="form-control-select"
                    @change="update_priority($event, listItem)"
                  >
                    <option
                      value="critical"
                      :selected="listItem.priority == 'critical'"
                    >
                      Critical
                    </option>
                    <option
                      value="high"
                      :selected="listItem.priority == 'high'"
                    >
                      High
                    </option>
                    <option
                      value="medium"
                      :selected="listItem.priority == 'medium'"
                    >
                      Medium
                    </option>
                    <option value="low" :selected="listItem.priority == 'low'">
                      Low
                    </option>
                    <option
                      value="lowest"
                      :selected="listItem.priority == 'lowest'"
                    >
                      Lowest
                    </option>
                  </select>
                </div>
              </b-card>
            </b-list-group-item>
          </draggable>
        </b-col> -->

        <b-col md="3">
          <div
            class="d-flex justify-content-between align-items-center px-2 head"
          >
            <span class="bold">
              <b-badge variant="info"> {{ progressList.length }} </b-badge>
              In Progress
            </span>
            <b-dropdown
              id="dropdown-grouped"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="transparant"
              right
              class="dropdown-icon-wrapper"
              size="sm"
            >
              <template #button-content>
                <div class="d-flex align-items-center">
                  <feather-icon icon="MenuIcon" size="18" />
                </div>
              </template>
              <b-dropdown-item>
                <div class="d-flex align-items-center">
                  <feather-icon icon="EditIcon" size="13" />
                  <span>Edit Label</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <draggable
            :list="progressList"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
            style="min-height: 300px"
            @change="onDragProgress"
          >
            <b-list-group-item
              v-for="(listItem, index) in progressList"
              :key="index"
              tag="li"
            >
              <b-card style="border-left: solid 2px cyan">
                <div class="d-flex">
                  <div class="ml-25">
                    <a
                      class="mb-0 font-weight-bold text-primary"
                      v-b-modal="'detail_todo_' + listItem.id"
                    >
                      {{ listItem.name }}
                    </a>
                    <div v-html="truncateText(listItem.description, 50)"></div>
                  </div>
                </div>
                <div class="d-flex justify-content-end align-items-center mt-1">
                  <select
                    class="form-control-select"
                    @change="update_priority($event, listItem)"
                  >
                    <option
                      value="high"
                      :selected="listItem.priority == 'high'"
                    >
                      High
                    </option>
                    <option
                      value="medium"
                      :selected="listItem.priority == 'medium'"
                    >
                      Medium
                    </option>
                    <option value="low" :selected="listItem.priority == 'low'">
                      Low
                    </option>
                  </select>
                </div>
              </b-card>
            </b-list-group-item>
          </draggable>
        </b-col>

        <b-col md="3">
          <div
            class="d-flex justify-content-between align-items-center px-2 head"
          >
            <span class="bold">
              <b-badge variant="success"> {{ completeList.length }} </b-badge>
              Completed
            </span>
            <b-dropdown
              id="dropdown-grouped"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="transparant"
              right
              class="dropdown-icon-wrapper"
              size="sm"
            >
              <template #button-content>
                <div class="d-flex align-items-center">
                  <feather-icon icon="MenuIcon" size="18" />
                </div>
              </template>
              <b-dropdown-item>
                <div class="d-flex align-items-center">
                  <feather-icon icon="EditIcon" size="13" />
                  <span>Edit Label</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <!-- draggable -->
          <draggable
            :list="completeList"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
            style="min-height: 300px"
            @change="onDragComplete"
          >
            <b-list-group-item
              v-for="(listItem, index) in completeList"
              :key="index"
              tag="li"
            >
              <b-card style="border-left: solid 2px green">
                <div class="d-flex">
                  <div class="ml-25">
                    <a
                      class="mb-0 font-weight-bold text-primary"
                      v-b-modal="'detail_todo_' + listItem.id"
                    >
                      {{ listItem.name }}
                    </a>
                    <div v-html="truncateText(listItem.description, 50)"></div>
                  </div>
                </div>
                <div class="d-flex justify-content-end align-items-center mt-1">
                  <select
                    class="form-control-select"
                    @change="update_priority($event, listItem)"
                  >
                    <option
                      value="high"
                      :selected="listItem.priority == 'high'"
                    >
                      High
                    </option>
                    <option
                      value="medium"
                      :selected="listItem.priority == 'medium'"
                    >
                      Medium
                    </option>
                    <option value="low" :selected="listItem.priority == 'low'">
                      Low
                    </option>
                  </select>
                </div>
              </b-card>
            </b-list-group-item>
          </draggable>
        </b-col>

        <b-col md="3">
          <div
            class="d-flex justify-content-between align-items-center px-2 head"
          >
            <span class="bold">
              <b-badge variant="danger"> {{ cancelList.length }} </b-badge>
              Cancelled
            </span>
            <b-dropdown
              id="dropdown-grouped"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="transparant"
              right
              class="dropdown-icon-wrapper"
              size="sm"
            >
              <template #button-content>
                <div class="d-flex align-items-center">
                  <feather-icon icon="MenuIcon" size="18" />
                </div>
              </template>
              <b-dropdown-item>
                <div class="d-flex align-items-center">
                  <feather-icon icon="EditIcon" size="13" />
                  <span>Edit Label</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <!-- draggable -->
          <draggable
            :list="cancelList"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
            style="min-height: 300px"
            @change="onDragCancel"
          >
            <b-list-group-item
              v-for="(listItem, index) in cancelList"
              :key="index"
              tag="li"
            >
              <b-card style="border-left: solid 2px orange">
                <div class="d-flex">
                  <div class="ml-25">
                    <a
                      class="mb-0 font-weight-bold text-primary"
                      v-b-modal="'detail_todo_' + listItem.id"
                    >
                      {{ listItem.name }}
                    </a>
                    <div v-html="truncateText(listItem.description, 50)"></div>
                  </div>
                </div>
                <div class="d-flex justify-content-end align-items-center mt-1">
                  <select
                    class="form-control-select"
                    @change="update_priority($event, listItem)"
                  >
                    <option
                      value="high"
                      :selected="listItem.priority == 'high'"
                    >
                      High
                    </option>
                    <option
                      value="medium"
                      :selected="listItem.priority == 'medium'"
                    >
                      Medium
                    </option>
                    <option value="low" :selected="listItem.priority == 'low'">
                      Low
                    </option>
                  </select>
                </div>
              </b-card>
            </b-list-group-item>
          </draggable>
        </b-col>
      </div>

      <b-modal
        id="add_task"
        hide-footer
        no-close-on-backdrop
        size="xl"
        title="New To-Do"
        data-bs-focus="false"
      >
        <form @submit.prevent="add_task">
          <div class="row">
            <div class="col-md-9">
              <b-form-group label="* Task Title">
                <b-form-input
                  id="todo_title"
                  v-model="todo_title"
                  placeholder="Task Title"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <quill-editor
                  v-model="todo_description"
                  :options="snowOption"
                />
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group label="* Status">
                <b-form-select
                  id="todo_status"
                  v-model="todo_status"
                  :options="statusOptions"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group>
              <b-form-group label="* Priority">
                <b-form-select
                  id="todo_priority"
                  v-model="todo_priority"
                  :options="priorityOptions"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group>
              <hr />
              <b-form-group label="* Project">
                <b-form-select
                  id="todo_project"
                  v-model="todo_project"
                  :options="projectOptions"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group>
              <!-- <b-form-group label="Milestone">
                <b-form-select
                  id="todo_milestone"
                  v-model="todo_milestone"
                  :options="milestoneOptions"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group> -->
              <hr />
              <!-- <b-form-group label="Author">
                <b-form-input
                  id="author"
                  v-model="todo_author"
                  type="text"
                  placeholder="Author"
                  required
                  size="sm"
                />
              </b-form-group> -->
              <b-form-group label="* Assigneed To">
                <b-form-select
                  id="todo_assignee"
                  v-model="todo_assignee"
                  :options="assigneeOptions"
                  required
                  size="sm"
                  multiple
                ></b-form-select>
              </b-form-group>
              <hr />
              <b-form-group label="* Start Date">
                <b-form-input
                  id="todo_start_date"
                  v-model="todo_start_date"
                  type="date"
                  placeholder="Start Date"
                  required
                  size="sm"
                />
              </b-form-group>
              <b-form-group label="* Due Date">
                <b-form-input
                  id="todo_due_date"
                  v-model="todo_due_date"
                  type="date"
                  placeholder="Due Date"
                  required
                  size="sm"
                />
              </b-form-group>
            </div>
            <div class="col-12 text-right mt-1">
              <b-button type="submit" variant="primary"> Add Task </b-button>
            </div>
          </div>
        </form>
      </b-modal>

      <b-modal
        v-for="(todo, index) in todos"
        :key="index"
        :id="'detail_todo_' + todo.id"
        hide-footer
        no-close-on-backdrop
        size="xl"
        title="Detail To-Do"
        data-bs-focus="false"
      >
        <form @submit.prevent="update_todo(todo)">
          <div class="row">
            <div class="col-md-9">
              <b-form-group label="To-Do Title">
                <b-form-input
                  id="todo_title"
                  v-model="todo.name"
                  placeholder="To-Do Title"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <quill-editor
                  v-model="todo.description"
                  :options="snowOption"
                />
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group label="Status">
                <b-form-select
                  id="todo_status"
                  v-model="todo.status"
                  :options="statusOptions"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group>
              <b-form-group label="Priority">
                <b-form-select
                  id="todo_priority"
                  v-model="todo.priority"
                  :options="priorityOptions"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group>
              <hr />
              <b-form-group label="Project">
                <b-form-select
                  id="todo_project"
                  v-model="todo.project"
                  :options="projectOptions"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group>
              <!-- <b-form-group label="Milestone">
                <b-form-select
                  id="todo_milestone"
                  v-model="todo.milestone"
                  :options="milestoneOptions"
                  required
                  size="sm"
                ></b-form-select>
              </b-form-group> -->
              <hr />
              <b-form-group label="Assigneed To">
                <b-form-select
                  id="todo_assignee"
                  v-model="todo.assigned_to"
                  :options="assigneeOptions"
                  required
                  size="sm"
                  multiple
                ></b-form-select>
              </b-form-group>
              <hr />
              <b-form-group label="Start Date">
                <b-form-input
                  id="todo_start_date"
                  v-model="todo.start_date"
                  type="date"
                  placeholder="Start Date"
                  required
                  size="sm"
                />
              </b-form-group>
              <b-form-group label="Due Date">
                <b-form-input
                  id="todo_due_date"
                  v-model="todo.due_date"
                  type="date"
                  placeholder="Due Date"
                  required
                  size="sm"
                />
              </b-form-group>
            </div>
            <div class="col-12 text-right mt-1">
              <b-button
                type="button"
                variant="outline-danger"
                class="mr-1"
                @click="confirmDelete(todo.id)"
              >
                Delete To-Do
              </b-button>
              <b-button type="submit" variant="primary">
                Update To-Do
              </b-button>
            </div>
          </div>
        </form>
      </b-modal>
    </b-card-actions>
  </div>
</template>

<style>
.head {
  background-color: #f8f9fa;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom: 3px solid #e9ecef;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.bold {
  font-weight: bold;
}

.form-control-select {
  border: 1px solid #e9ecef;
  border-radius: 0.8rem;
  padding: 0.3rem 1rem;
  font-size: 0.875rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ql-editor {
  min-height: 300px;
}
</style>

<script>
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    draggable,
    Prism,
    ToastificationContent,
    quillEditor,
  },

  data() {
    return {
      newList: [],
      blockedList: [],
      progressList: [],
      completeList: [],
      cancelList: [],
      todos: [],
      todo_title: "",
      todo_description: "",
      todo_status: "",
      todo_priority: "",
      todo_milestone: "",
      todo_author: 1,
      todo_assignee: "",
      todo_due_date: "",
      todo_start_date: "",
      todo_project: "",
      snowOption: {
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
        },
        placeholder: "Type for description",
        theme: "snow",
      },
      statusOptions: [
        { value: "new", text: "New" },
        { value: "blocked", text: "Blocked" },
        { value: "inprogress", text: "In Progress" },
        { value: "complete", text: "Complete" },
        { value: "cancel", text: "Cancel" },
      ],
      priorityOptions: [
        { value: "low", text: "Low" },
        { value: "medium", text: "Medium" },
        { value: "high", text: "High" },
      ],
      milestoneOptions: [],
      assigneeOptions: [],
      projectOptions: [],
    };
  },

  directives: {
    Ripple,
  },

  created() {
    if (this.$isMobile()) {
      this.$router.push({ name: "todo-list" });
    }
  },

  mounted() {
    this.get_todo();
    this.get_user();
    this.get_project();
  },

  methods: {
    get_user() {
      this.callApi({
        url: "/users/getAll",
        method: "GET",
        params: { limit: 100 },
        success: (res) => {
          res.result.data.map((item) => {
            this.assigneeOptions.push({
              value: `${item.id}`,
              text: item.name,
            });
          });

          if (this.getRole() == "staff") {
            // exclude all, except current user
            this.assigneeOptions = [
              {
                value: JSON.parse(localStorage.getItem("sw_auth_data")).id,
                text: JSON.parse(localStorage.getItem("sw_auth_data")).name,
              },
            ];
          } else {
            // exclude current user
            this.assigneeOptions = this.assigneeOptions.filter(
              (item) => item.value != 1
            );
          }
        },
      });
    },

    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete everything.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // delete todo
            this.callApi({
              method: "DELETE",
              url: "/tasks/delete/" + this.hashid(id),
              success: (res) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: "Todo has been Deleted",
                    variant: "success",
                  },
                });

                this.get_todo();

                this.$bvModal.hide("detail_todo_" + id);
              },
            });
          }
        });
    },

    get_project() {
      this.callApi({
        url: "/projects/fetch",
        method: "GET",
        success: (res) => {
          res.result.map((item) => {
            this.projectOptions.push({
              value: item.id,
              text: item.name,
            });
          });
        },
      });
    },

    get_milestone() {
      this.milestoneOptions = [];
      this.callApi({
        url: "/milestones/fetch",
        params: {
          project_id: this.hashid(this.todo_project),
        },
        method: "GET",
        success: (res) => {
          res.result.map((item) => {
            this.milestoneOptions.push({
              value: item.id,
              text: item.name,
            });
          });
        },
      });
    },

    onRefresh() {
      this.get_todo();
    },

    get_todo() {
      this.$refs["mainCard"].showLoading = true;

      let params = {};

      // if role == staff
      if (this.getRole() == "staff") {
        params = {
          assigned_to: this.hashid(this.getAuthId()),
          // created_by: this.hashid(this.getAuthId()),
        };
      }

      if (this.getRole() == "manager") {
        params = {
          // assigned_to: this.hashid(this.getAuthId()),
          created_by: this.hashid(this.getAuthId()),
        };
      }

      this.callApi({
        method: "GET",
        url: "/tasks/fetch",
        params: params,
        success: (res) => {
          // reset newList
          this.newList = [];
          // reset blockedList
          this.blockedList = [];
          // reset progressList
          this.progressList = [];
          // reset completeList
          this.completeList = [];
          // reset cancelList
          this.cancelList = [];
          // reset todos
          this.todos = res.result;

          // foreach res data
          res.result.forEach((element) => {
            // if status == 1
            if (element.status == "new") {
              this.newList.push(element);
            }
            // if status == 2
            if (element.status == "blocked") {
              this.blockedList.push(element);
            }
            // if status == 3
            if (element.status == "inprogress") {
              this.progressList.push(element);
            }
            // if status == 4
            if (element.status == "complete") {
              this.completeList.push(element);
            }
            // if status == 5
            if (element.status == "cancel") {
              this.cancelList.push(element);
            }
          });
          this.$refs["mainCard"].showLoading = false;
        },
        error: (err) => {
          this.$refs["mainCard"].showLoading = false;
        },
      });
    },

    dateTimeNow() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;
      return dateTime;
    },

    onDragNew(event) {
      if (event.added) {
        this.callApi({
          method: "POST",
          url: "/tasks/update/" + this.hashid(event.added.element.id),
          data: {
            name: event.added.element.name,
            description: event.added.element.description,
            priority: event.added.element.priority,
            date_added: event.added.element.date_added,
            start_date: event.added.element.start_date,
            due_date: event.added.element.due_date,
            date_completed: event.added.element.date_completed,
            project_id: event.added.element.project_id,
            assigned_to: event.added.element.assigned_to.join(","),
            created_by: event.added.element.created_by.id,
            status: "new",
          },
          success: (res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Todo has been moved to New",
                variant: "success",
              },
            });
          },
        });
      }
    },

    onDragBlocked(event) {
      if (event.added) {
        this.callApi({
          method: "POST",
          url: "/tasks/update/" + this.hashid(event.added.element.id),
          data: {
            name: event.added.element.name,
            description: event.added.element.description,
            priority: event.added.element.priority,
            date_added: event.added.element.date_added,
            start_date: event.added.element.start_date,
            due_date: event.added.element.due_date,
            date_completed: event.added.element.date_completed,
            project_id: event.added.element.project_id,
            assigned_to: event.added.element.assigned_to.join(","),
            created_by: event.added.element.created_by.id,
            status: "blocked",
          },
          success: (res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Todo has been moved to Blocked",
                variant: "success",
              },
            });
          },
        });
      }
    },

    onDragProgress(event) {
      if (event.added) {
        this.callApi({
          method: "POST",
          url: "/tasks/update/" + this.hashid(event.added.element.id),
          data: {
            name: event.added.element.name,
            description: event.added.element.description,
            priority: event.added.element.priority,
            date_added: event.added.element.date_added,
            start_date: event.added.element.start_date,
            due_date: event.added.element.due_date,
            date_completed: event.added.element.date_completed,
            project_id: event.added.element.project_id,
            assigned_to: event.added.element.assigned_to.join(","),
            created_by: event.added.element.created_by.id,
            status: "inprogress",
          },
          success: (res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Todo has been moved to Progress",
                variant: "success",
              },
            });
          },
        });
      }
    },

    onDragComplete(event) {
      if (event.added) {
        this.callApi({
          method: "POST",
          url: "/tasks/update/" + this.hashid(event.added.element.id),
          data: {
            name: event.added.element.name,
            description: event.added.element.description,
            priority: event.added.element.priority,
            date_added: event.added.element.date_added,
            start_date: event.added.element.start_date,
            due_date: event.added.element.due_date,
            date_completed: event.added.element.date_completed,
            project_id: event.added.element.project_id,
            assigned_to: event.added.element.assigned_to.join(","),
            created_by: event.added.element.created_by.id,
            status: "complete",
          },
          success: (res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Todo has been moved to Complete",
                variant: "success",
              },
            });
          },
        });
      }
    },

    onDragCancel(event) {
      if (event.added) {
        this.callApi({
          method: "POST",
          url: "/tasks/update/" + this.hashid(event.added.element.id),
          data: {
            name: event.added.element.name,
            description: event.added.element.description,
            priority: event.added.element.priority,
            date_added: event.added.element.date_added,
            start_date: event.added.element.start_date,
            due_date: event.added.element.due_date,
            date_completed: event.added.element.date_completed,
            project_id: event.added.element.project_id,
            assigned_to: event.added.element.assigned_to.join(","),
            created_by: event.added.element.created_by.id,
            status: "cancel",
          },
          success: (res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Todo has been moved to Cancel",
                variant: "success",
              },
            });
          },
        });
      }
    },

    update_priority(event, data) {
      this.callApi({
        method: "POST",
        url: "/tasks/update/" + this.hashid(data.id),
        data: {
          priority: event.target.value,
        },
        success: (res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Todo has been updated",
              variant: "success",
            },
          });
        },
      });
    },

    add_task() {
      this.callApi({
        method: "POST",
        url: "/tasks/create",
        data: {
          name: this.todo_title,
          description: this.todo_description,
          priority: this.todo_priority,
          start_date: this.todo_start_date,
          due_date: this.todo_due_date,
          date_completed: this.todo_date_completed,
          project_id: this.todo_project,
          assigned_to: this.todo_assignee.join(","),
          created_by: JSON.parse(localStorage.getItem("sw_auth_data")).id,
          date_added: this.dateTimeNow(),
          update_at: this.dateTimeNow(),
          status: this.todo_status,
        },
        success: (res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Todo has been added",
              variant: "success",
            },
          });
          this.todo_title = "";
          this.todo_description = "";
          this.todo_priority = "";
          this.todo_start_date = "";
          this.todo_due_date = "";
          this.todo_date_completed = "";
          this.todo_project_id = "";
          this.todo_assigned_to = "";
          this.todo_created_by = "";
          this.get_todo();

          // hide b-modal id add_task
          this.$bvModal.hide("add_task");
        },
      });
    },

    update_todo(todo) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to update everything.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // delete todo
            this.callApi({
              method: "POST",
              url: "/tasks/update/" + this.hashid(todo.id),
              data: {
                name: todo.name,
                description: todo.description,
                priority: todo.priority,
                date_added: todo.date_added,
                start_date: todo.start_date,
                due_date: todo.due_date,
                date_completed: todo.date_completed,
                project_id: todo.project_id,
                assigned_to: todo.assigned_to.join(","),
                created_by: todo.created_by.id,
                update_at: this.dateTimeNow(),
                status: todo.status,
              },
              success: (res) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: "Todo has been updated",
                    variant: "success",
                  },
                });

                this.get_todo();

                this.$bvModal.hide("detail_todo_" + todo.id);
              },
            });
          }
        });
    },
  },
};
</script>

<style></style>
